import { lazy } from "react";
const Agregar = lazy(() => import("./Agregar"));
const Listado = lazy(() => import("./Listado"));
const Editar = lazy(() => import("./Editar"));
const AddBanco = lazy(() => import("./AddBanco"));
const AddEmpresaEmisiones = lazy(() => import("./AddEmpresaEmisiones"));

const empresasRoutes = [
  {
    path: "/empresas/agregar",
    component: Agregar,
  },
  {
    path: "/empresas/editar",
    component: Editar,
  },
  {
    path: "/empresas/bancos",
    component: AddBanco,
  },
  {
    path: "/empresas/emisiones",
    component: AddEmpresaEmisiones,
  },
  {
    path: "/empresas",
    component: Listado,
  },
];

export default empresasRoutes;
