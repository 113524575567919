import { lazy } from "react";
const Agregar = lazy(() => import("./Agregar"));

const anexo69bRoutes = [
  {
    path: "/anexo69b/agregar",
    component: Agregar
  },
];

export default anexo69bRoutes;