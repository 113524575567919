import { lazy } from "react";
const Agregar = lazy(() => import("./Agregar"));
const Listado = lazy(() => import("./Listado"));
const Editar = lazy(() => import("./Editar"));
const usuariosRoutes = [
  {
    path: "/usuarios/agregar",
    component: Agregar
  },
  {
    path: "/usuarios/editar",
    component: Editar
  },
  {
    path: "/usuarios",
    component: Listado
  }
];

export default usuariosRoutes;
