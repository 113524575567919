import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import empresasRoutes from "./views/empresas/empresasRoutes";
import usuariosRoutes from "./views/usuarios/usuariosRoutes";
import clientesRoutes from "./views/clientes/clientesRoutes";
import versionesRoutes from "./views/versiones/versionesRoutes";
import postsRoutes from "./views/post/blogpostsRoutes";
import cotizacionesRoutes from "./views/cotizaciones/cotizacionesRoutes";
import publicRoutes from "./views/public/publicRoutes";
import licenciasRoutes from "./views/licencias/licenciasRoutes";
import anexo69bRoutes from "./views/anexo69b/anexo69bRoutes";
import apiCollectionRoutes from "./views/apicollection/apiCollectionRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/v1" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

let routes = []
const user = JSON.parse(localStorage.getItem("auth_user"));
const userRole = user?.role ? user.role: 0;

if (userRole == 1){
  routes = [
    ...sessionsRoutes,
    ...publicRoutes,
    {
      path: "/",
      component: AuthGuard,
      routes: [      
        ...dashboardRoutes,
        ...empresasRoutes,
        ...usuariosRoutes,
        ...clientesRoutes,
        ...versionesRoutes,
        ...cotizacionesRoutes,
        ...postsRoutes,
        ...licenciasRoutes,
        ...anexo69bRoutes,
        ...apiCollectionRoutes,
        ...redirectRoute,
        ...errorRoute
      ]
    }
  ];
}
if (userRole == 0){
  routes = [
    ...sessionsRoutes,
    ...publicRoutes,
    {
      path: "/",
      component: AuthGuard,
      routes: [      
        ...dashboardRoutes,
        ...clientesRoutes,
        ...cotizacionesRoutes,
        ...postsRoutes,
        ...licenciasRoutes,
        ...anexo69bRoutes,
        ...apiCollectionRoutes,
        ...redirectRoute,
        ...errorRoute
      ]
    }
  ];
}
export default routes;
