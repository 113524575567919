import { lazy } from "react";
const DescargarJSON = lazy(() => import("./DescargarJSON"));

const apiCollectionRoutes = [
  {
    path: "/api/v1/collection",
    component: DescargarJSON
  },
];

export default apiCollectionRoutes;