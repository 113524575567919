import axios from "axios";
import localStorageService from "./localStorageService";

class JwtAuthService {
    loginWithEmailAndPassword = (email, password) => {
    const apiUrl = process.env.NODE_ENV === "development" ? "http://localhost:8000/api/login" : '/api/login'; 
    return new Promise((resolve, reject) => {
        axios.post(apiUrl, {email, password})
        .then(data => {
          resolve(this.user);
          this.setSession(data.data.data.token);
          this.setUser({
            userId: data.data.user[0].id,
            role: data.data.user[0].admin,
            displayName: data.data.user[0].nombre,
            email: data.data.user[0].email,
            token: data.data.data.token
          });
        }).catch(error => {
          console.log('ERROR', error.response);
          alert(error.response.data.error)
          reject(error)
        });
    })
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      this.setSession(localStorage.getItem("jwt_token"));
      this.setUser(JSON.parse(localStorage.getItem("auth_user")));
      return data;
    });
  };

  

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
