import { lazy } from "react";
const Agregar = lazy(() => import("./Agregar"));
const Listado = lazy(() => import("./Listado"));
const Editar = lazy(() => import("./Editar"));
const ListadoCfdis = lazy(() => import("./ListadoCfdis"));
const clientesRoutes = [
  {
    path: "/clientes/agregar",
    component: Agregar
  },
  {
    path: "/clientes/editar",
    component: Editar
  },
  {
    path: "/clientes/cfdis",
    component: ListadoCfdis
  },
  {
    path: "/clientes",
    component: Listado
  }
];

export default clientesRoutes;
