import { lazy } from "react";
const Detalle = lazy(() => import("./Detalle"));
const DetalleSuscripcion = lazy(() => import("./DetalleSuscripcion"));
const Descargar = lazy(() => import("./Descargar"));
const DescargarSuscripcion = lazy(() => import("./DescargarSuscripcion"));
const AgregarRecurrente = lazy(() => import("./AgregarRecurrente"));
const AgregarNoRecurrente = lazy(() => import("./AgregarNoRecurrente"));
const ListadoCotizaciones = lazy(() => import("./ListadoCotizaciones"));
const ListadoSubscriptions = lazy(() => import("./ListadoSubscriptions"));
const Autorizar = lazy(() => import("./Autorizar"));
const AutorizarCobro = lazy(() => import("./AutorizarCobro"));
const EditarNoRecurrente = lazy(() => import("./EditarNoRecurrente"));
const EditarRecurrente = lazy(() => import("./EditarRecurrente"));
const AutorizarNoRecurrente = lazy(() => import("./AutorizarNoRecurrente"));
const CotizacionesPendientesExcel = lazy(() =>
  import("./CotizacionesPendientesExcel")
);
const SuscripcionesPendientesExcel = lazy(() =>
  import("./SuscripcionesPendientesExcel")
);
const CancelarSuscripcion = lazy(() => import("./CancelarSuscripcion"));
const EstadoDeCuenta = lazy(() => import("./EstadoDeCuenta"));
const user = JSON.parse(localStorage.getItem("auth_user"));
const userRole = user?.role ? user.role: 0;

const cotizacionesRoutes = [
  {
    path: "/suscripciones/agregar",
    component: AgregarRecurrente,
  },
  {
    path: "/cotizaciones/agregar",
    component: AgregarNoRecurrente,
  },
  {
    path: "/suscripciones/autorizar",
    component: Autorizar,
  },
  {
    path: "/suscripciones/autorizarcobro",
    component: AutorizarCobro,
  },
  {
    path: "/cotizaciones/autorizarnorecurrente",
    component: AutorizarNoRecurrente,
  },
  {
    path: "/cotizaciones/editar",
    component: EditarNoRecurrente,
  },
  {
    path: "/cotizaciones/detalle",
    component: Detalle,
  },
  {
    path: "/suscripciones/editar",
    component: EditarRecurrente,
  },
  {
    path: "/suscripciones/detalle",
    component: DetalleSuscripcion,
  },
  {
    path: "/suscripciones/suscripcion/cancelar",
    component: userRole == 1 ? CancelarSuscripcion : ListadoSubscriptions
  },
  {
    path: "/cotizaciones/descargar",
    component: Descargar,
  },
  {
    path: "/suscripciones/descargar",
    component: DescargarSuscripcion,
  },
  {
    path: "/suscripciones/estadodecuenta",
    component: EstadoDeCuenta,
  },
  {
    path: "/cotizaciones/excelactivas",
    component: CotizacionesPendientesExcel,
  },
  {
    path: "/suscripciones/excelactivas",
    component: SuscripcionesPendientesExcel,
  },
  {
    path: "/cotizaciones",
    component: ListadoCotizaciones,
  },
  {
    path: "/suscripciones",
    component: ListadoSubscriptions,
  },
];

export default cotizacionesRoutes;