import { lazy } from "react";
// const ExtensionesDetalle  = lazy(() => import("./extensions/ExtensionesDetalle"));
const ExtensionesListado  = lazy(() => import("./extensions/ExtensionesListado"));
const UsersListado  = lazy(() => import("./companies_users/UsersListado"));
const EmpresasDetalle  = lazy(() => import("./companies/EmpresasDetalle"));
const EmpresasListado = lazy(() => import("./companies/EmpresasListado"));

const licenciasRoutes = [
  {
    path: "/licencias/empresas/detalle",
    component: EmpresasDetalle
  },
  {
    path: "/licencias/empresas",
    component: EmpresasListado
  },
  {
    path: "/licencias/usuarios",
    component: UsersListado
  },
  // {
  //   path: "/licencias/extensiones/detalle",
  //   component: ExtensionesDetalle
  // },
  {
    path: "/licencias/extensiones",
    component: ExtensionesListado
  }
];

export default licenciasRoutes;