import "../fake-db";
import React, { Suspense, useState } from "react";
import "../styles/app/app.scss";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import ServicesContext from "./servicesContext";
import VersionServicesContext from "./versionServicesContext";
import ContractsContext from "./contractsContext";

function App() {
  const [contracts, setContracts] = useState([])
  const [contractsEdit, setContractsEdit] = useState([])
  const [services, setServices] = useState([])
  const [servicesEdit, setServicesEdit] = useState([])
  const [versionServices, setVersionServices] = useState([])
  const [versionServicesEdit, setVersionServicesEdit] = useState([])
  return (
    <>
    
    <AppContext.Provider value={{ routes }}>
    <Provider store={Store}>
      <Auth>
        <Suspense fallback={<Loading></Loading>}>
          <ContractsContext.Provider value={{ contracts, setContracts, contractsEdit, setContractsEdit }}>
          <ServicesContext.Provider	value={{ services, setServices, servicesEdit, setServicesEdit }}>
          <VersionServicesContext.Provider	value={{ versionServices, setVersionServices, versionServicesEdit, setVersionServicesEdit }}>
            <Router history={history}>{renderRoutes(RootRoutes)}</Router>
          </VersionServicesContext.Provider>
          </ServicesContext.Provider>
          </ContractsContext.Provider>
        </Suspense>
      </Auth>
    </Provider>
    </AppContext.Provider>
    </>
  
  );
}

export default App;
