import { lazy } from "react";
const Cotizacion = lazy(() => import("./Cotizacion"));
const Suscripcion = lazy(() => import("./Suscripcion"));
const SuscripcionSuccess = lazy(() => import("./SuscripcionSuccess"));
const SuscripcionCobroSuccess = lazy(() => import("./SuscripcionCobroSuccess"));
const CotizacionSuccess = lazy(() => import("./CotizacionSuccess"));
const Folios = lazy(() => import("./Folios"));
const FoliosSuccess = lazy(() => import("./FoliosSuccess"));

const publicRoutes = [
  {
    path: "/cotizacion",
    component: Cotizacion
  },
  {
    path: "/success",
    component: CotizacionSuccess
  },
  {
    path: "/suscripcion",
    component: Suscripcion
  },
  {
    path: "/suscripcionsuccess",
    component: SuscripcionSuccess
  },
  {
    path: "/suscripcioncobrosuccess",
    component: SuscripcionCobroSuccess
  },
  {
    path: "/folios",
    component: Folios
  },
  {
    path: "/foliossuccess",
    component: FoliosSuccess
  },
];

export default publicRoutes;