import { lazy } from "react";
const Agregar = lazy(() => import("./Agregar"));
const Listado = lazy(() => import("./Listado"));
const Editar= lazy(() => import("./Editar"));
const postsRoutes = [
  {
    path: "/posts/agregar",
    component: Agregar
  },
  {
    path: "/posts/editar",
    component: Editar
  },
  {
    path: "/posts",
    component: Listado
  }
];

export default postsRoutes;