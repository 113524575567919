import { lazy } from "react";
const Agregar = lazy(() => import("./Agregar"));
const Listado = lazy(() => import("./Listado"));
const Editar= lazy(() => import("./Editar"));
const versionesRoutes = [
  {
    path: "/versiones/agregar",
    component: Agregar
  },
  {
    path: "/versiones/editar",
    component: Editar
  },
  {
    path: "/versiones",
    component: Listado
  }
];

export default versionesRoutes;